import { Box, Burger, Header as MantineHeader, Title } from '@mantine/core';
import { useResource } from '@pankod/refine-core';
import { useNavBar } from './hooks';

export const Header: React.FC = () => {
  const { opened, setOpened } = useNavBar();
  const { resource } = useResource();

  return (
    <MantineHeader height={56} p="md">
      <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Burger mr="xl" opened={opened} size="sm" onClick={() => setOpened((o) => !o)} />
        <Title order={5}>{resource.label}</Title>
      </Box>
    </MantineHeader>
  );
};
