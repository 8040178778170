import { Avatar, Badge, Box, Group, Stack } from '@mantine/core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';
import { PageLayout } from 'components/PageLayout';
import { ShowDrawer, ShowDrawerProvider } from 'components/ShowDrawer';
import { Table, TableActions, TablePagination, usePopulateColumn } from 'components/Table';
import { ISale } from 'interfaces';
import { useMemo, useState } from 'react';
import { BiCar } from 'react-icons/bi';
import { ShowDetails } from './ShowDetails';

export const List: React.FC = () => {
  const [id, setId] = useState(0);

  const columns: Array<Column> = useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'product',
        Header: 'Product',
        accessor: 'product_id',
        Cell: ({ cell }) =>
          usePopulateColumn('products', cell, (data) => (
            <Group>
              <Avatar radius="sm" size="lg" src={data?.data.image}>
                <BiCar size={28} />
              </Avatar>
              <Box>
                {data?.data.brand} {data?.data.model} {data?.data.model_year}
              </Box>
            </Group>
          )),
      },
      // {
      //   id: "brand",
      //   Header: "Brand",
      //   accessor: "product_id",
      // },
      // {
      //   id: "model",
      //   Header: "Model",
      //   accessor: "product_id",

      // },
      // {
      //   id: "model_year",
      //   Header: "Model Year",
      //   accessor: "product_id",
      // },
      {
        id: 'customer',
        Header: 'Customer',
        accessor: 'user_id',
        Cell: ({ cell }) =>
          usePopulateColumn('users', cell, (data) => (
            <Group>
              <Avatar radius="sm" size="lg" src={data?.data.image} />
              <Box>
                {data?.data.title} {data?.data.first_name} {data?.data.last_name}
              </Box>
            </Group>
          )),
      },
      {
        id: 'payment_type',
        Header: 'Payment type',
        accessor: 'payment_type',
        Cell: ({ value }) => <Badge color="blue">{value}</Badge>,
      },
      {
        id: 'payment_status',
        Header: 'Payment status',
        accessor: 'payment_status',
        Cell: ({ value }) => <Badge color="blue">{value}</Badge>,
      },

      // {
      //   id: 'created_at',
      //   Header: 'Created at',
      //   accessor: 'created_at',
      // },
      // {
      //   id: 'updated_at',
      //   Header: 'Updated at',
      //   accessor: 'updated_at',
      // },
      {
        id: 'action',
        accessor: 'product_id',
        Cell: ({ cell, row }) =>
          usePopulateColumn('products', cell, (data) => {
            const product: any = row.original;
            return (
              <TableActions
                withShowButton
                withShowDrawerButton
                // withDeleteButton
                // withEditButton
                id={product?.id}
                label={`${data?.data.brand} ${data?.data.model}`}
                onShowDrawer={() => setId(product?.id)}
              />
            );
          }),
      },
    ],
    []
  );

  const tableProps = useTable<ISale>({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ShowDrawerProvider>
      <PageLayout withCreateButton>
        <Stack spacing="lg">
          <Table {...tableProps} />
          <TablePagination {...tableProps} />
        </Stack>
      </PageLayout>
      <ShowDrawer title="Sale details">
        <Box pt="md">
          <ShowDetails id={id} />
        </Box>
      </ShowDrawer>
    </ShowDrawerProvider>
  );
};
