import { Group, Stack, Text } from '@mantine/core';
import { IAccount } from 'interfaces';

type DetailProps = {
  record?: IAccount;
};

export const UsernameDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Username</Text>
        <Text>{record?.username}</Text>
      </Group>
    </Stack>
  );
};
