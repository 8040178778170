import { createStyles, ScrollArea, Table as MantineTable } from '@mantine/core';
import { Cell, HeaderGroup, Row, UseTableProps } from '@pankod/refine-react-table';

const useStyles = createStyles(() => ({
  td: {
    borderBottom: '0 !important',
  },
}));

export const Table: React.FC<UseTableProps> = (props) => {
  const { getTableBodyProps, headerGroups, prepareRow, page } = props;
  const { classes } = useStyles();

  return (
    <ScrollArea>
      <MantineTable
        highlightOnHover
        sx={(theme) => ({
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          whiteSpace: 'nowrap',
        })}
        verticalSpacing="sm"
      >
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: Row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: Cell) => {
                  return (
                    <td {...cell.getCellProps()} className={classes.td}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </MantineTable>
    </ScrollArea>
  );
};
