import {
  ColorScheme,
  ColorSchemeProvider,
  Global,
  MantineProvider,
  useMantineTheme
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { BRAND_COLOR } from 'constants/env';

export const ThemeProvider: React.FC = ({ children }) => {
  const theme = useMantineTheme();

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'dms-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });

  const toggleColorScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withNormalizeCSS
        theme={{
          colorScheme,
          fontFamily: `system-ui, ${theme.fontFamily}`,
          headings: { fontFamily: `system-ui, ${theme.fontFamily}` },
          primaryColor: BRAND_COLOR,
        }}
      >
        <Global
          styles={(theme) => ({
            body: {
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
            },
          })}
        />
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
