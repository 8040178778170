import { Avatar, Group, Stack, Text } from '@mantine/core';
import { IProduct } from 'interfaces';
import { BiCar } from 'react-icons/bi';

type DetailProps = {
  record?: IProduct;
};

export const ImageDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Avatar radius="sm" size="xl" src={record?.image}>
        <BiCar size={28} />
      </Avatar>
    </Stack>
  );
};

export const BrandDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Brand</Text>
        <Text>{record?.brand}</Text>
      </Group>
    </Stack>
  );
};

export const ModelDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Model</Text>
        <Text>{record?.model}</Text>
      </Group>
    </Stack>
  );
};

export const ModelYearDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Model year</Text>
        <Text>{record?.model_year}</Text>
      </Group>
    </Stack>
  );
};

export const QuantityDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Quantity</Text>
        <Text>{record?.quantity}</Text>
      </Group>
    </Stack>
  );
};

export const ChassisNumberDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Chassis number</Text>
        <Text>{record?.chassis_number}</Text>
      </Group>
    </Stack>
  );
};

export const ColorDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Color</Text>
        <Text>{record?.color}</Text>
      </Group>
    </Stack>
  );
};

export const PowerDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Power</Text>
        <Text>{record?.power}</Text>
      </Group>
    </Stack>
  );
};

export const WeightDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Weight</Text>
        <Text>{record?.weight_amount}</Text>
      </Group>
    </Stack>
  );
};

export const PriceDetail: React.FC<DetailProps> = ({ record }) => {
  const formatPrice = (value?: number) => {
    if (!value) return 0;

    return new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 2,
    }).format(value);
  };

  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Price</Text>
        <Text>
          {record?.currency} {formatPrice(record?.price)}
        </Text>
      </Group>
    </Stack>
  );
};
