import { Button, Container, Image, Paper, SimpleGrid, Stack, Title } from '@mantine/core';
import { useLogin } from '@pankod/refine-core';
import { ThemeProvider } from 'components/Theme';
import { SITE_LOGO } from 'constants/env';
import { ILogin } from 'interfaces';
import { PasswordField } from 'pages/accounts';
import { EmailField } from 'pages/users';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const Login: React.FC<any> = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleSubmit, ...formProps } = useForm<ILogin>();
  const { mutate: login } = useLogin<ILogin>();

  const onSubmit = async (fieldValues: ILogin) => {
    setSubmitting(true);

    login(fieldValues, {
      onError(error) {
        alert(error.message);
      },
      onSettled() {
        setSubmitting(false);
      },
    });
  };

  return (
    <ThemeProvider>
      <Container my={40} size={420}>
        <Stack align="center">
          <Image height={96} src={SITE_LOGO} />
          <Title align="center" order={1}>
            Welcome back!
          </Title>
        </Stack>
        <Paper mt={28} p={28} radius="md">
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid spacing="md">
              <EmailField formProps={formProps} placeholder="richard.hendricks@piedpiper.com" />
              <PasswordField formProps={formProps} placeholder="●●●●●●●●" />
              <Button fullWidth loading={isSubmitting} type="submit">
                Log in
              </Button>
            </SimpleGrid>
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
