import { Button, SimpleGrid } from '@mantine/core';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';
import { FormSkeleton } from 'components/Form';
import { IProduct } from 'interfaces';
import { useEffect } from 'react';
import {
  BrandField,
  ChassisNumberField,
  ColorField,
  ModelField,
  ModelYearField,
  PowerField,
  PriceField,
  QuantityField,
  StockLocationField,
  WeightField,
} from './Fields';

export const EditForm: React.FC<{ record?: IProduct }> = ({ record }) => {
  const { list } = useNavigation();

  const {
    handleSubmit,
    refineCore: {
      formLoading,
      mutationResult: { isLoading },
      onFinish,
    },
    resetField,
    ...formProps
  } = useForm({
    refineCoreProps: {
      id: record?.id,
      resource: 'products',
      redirect: false,
      onMutationSuccess() {
        list('products');
      },
    },
  });

  const { options } = useSelect({
    resource: 'stock_locations',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: record?.stock_location_id,
  });

  useEffect(() => {
    resetField('stock_location_id');
    // eslint-disable-next-line
  }, [options]);

  return (
    <>
      <form hidden={formLoading ? true : false} onSubmit={handleSubmit(onFinish)}>
        <SimpleGrid spacing="md">
          <StockLocationField formProps={formProps} options={options} />
          <BrandField formProps={formProps} />
          <ModelField formProps={formProps} />
          <ModelYearField formProps={formProps} />
          <QuantityField formProps={formProps} />
          <ChassisNumberField formProps={formProps} />
          <ColorField formProps={formProps} />
          <PowerField formProps={formProps} />
          <WeightField formProps={formProps} />
          <PriceField formProps={formProps} />
          <Button loading={isLoading} mt="xs" type="submit">
            Update
          </Button>
        </SimpleGrid>
      </form>
      {formLoading && <FormSkeleton />}
    </>
  );
};
