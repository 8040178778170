import { ActionIcon, Box, Group, Image, useMantineColorScheme } from '@mantine/core';
import { SITE_LOGO } from 'constants/env';
import { BiMoon, BiSun } from 'react-icons/bi';

export const Brand: React.FC = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Box
      sx={(theme) => ({
        paddingLeft: theme.spacing.xs,
        paddingRight: theme.spacing.xs,
      })}
    >
      <Group position="apart" sx={{ height: 96 }}>
        <Image height={48} src={SITE_LOGO} />
        <ActionIcon size={30} variant="hover" onClick={() => toggleColorScheme()}>
          {colorScheme === 'dark' ? <BiSun /> : <BiMoon />}
        </ActionIcon>
      </Group>
    </Box>
  );
};
