import { ModalsProvider } from '@mantine/modals';
import { Refine } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { dataProvider } from '@pankod/refine-supabase';
import { AppShell } from 'components/AppShell';
import { ThemeProvider } from 'components/Theme';
import { AccountCreate, AccountEdit, AccountList, AccountShow } from 'pages/accounts';
import { LoginPage, SignupPage } from 'pages/auth';
import { ComingSoon } from 'pages/coming-soon';
import { CreditorCreate, CreditorEdit, CreditorList, CreditorShow } from 'pages/creditors';
import { CustomerCreate, CustomerEdit, CustomerList, CustomerShow } from 'pages/customers';
import { OrderCreate, OrderEdit, OrderShow } from 'pages/orders';
import { ProductCreate, ProductEdit, ProductList, ProductShow } from 'pages/products';
import { SaleCreate, SaleEdit, SaleList, SaleShow } from 'pages/sales';
import {
  BiBadge,
  BiBarChart,
  BiCar,
  BiCog,
  BiFile,
  BiLineChart,
  BiTachometer,
  BiTransfer,
  BiUser,
  BiUserPin,
  BiWallet,
} from 'react-icons/bi';
import authProvider from 'utils/authProvider';
import { supabaseClient } from 'utils/supabase';

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ModalsProvider>
        <Refine
          authProvider={authProvider}
          dataProvider={dataProvider(supabaseClient)}
          Layout={AppShell}
          LoginPage={LoginPage}
          resources={[
            {
              name: 'dashboard',
              options: { label: 'Dashboard' },
              icon: <BiTachometer size={20} />,
              list: ComingSoon,
            },
            {
              name: 'sales',
              options: { label: 'Sales', badge: 'New' },
              icon: <BiBarChart size={20} />,
              list: SaleList,
              create: SaleCreate,
              show: SaleShow,
              edit: SaleEdit,
            },
            {
              name: 'products',
              options: { label: 'Inventory', badge: 'New' },
              icon: <BiCar size={20} />,
              list: ProductList,
              create: ProductCreate,
              show: ProductShow,
              edit: ProductEdit,
            },
            {
              name: 'orders',
              options: { label: 'Order List' },
              icon: <BiFile size={20} />,
              list: ComingSoon,
              create: OrderCreate,
              show: OrderShow,
              edit: OrderEdit,
            },
            {
              name: 'internal-financing',
              options: { label: 'Internal Financing' },
              icon: <BiTransfer size={20} />,
              list: ComingSoon,
            },
            {
              name: 'customers',
              options: { label: 'Customers', badge: 'New' },
              icon: <BiUser size={20} />,
              list: CustomerList,
              create: CustomerCreate,
              show: CustomerShow,
              edit: CustomerEdit,
            },
            {
              name: 'creditors',
              options: { label: 'Creditors', badge: 'New' },
              icon: <BiWallet size={20} />,
              list: CreditorList,
              create: CreditorCreate,
              show: CreditorShow,
              edit: CreditorEdit,
            },
            {
              name: 'accounts',
              options: { label: 'Accounts', badge: 'New' },
              icon: <BiUserPin size={20} />,
              list: AccountList,
              create: AccountCreate,
              show: AccountShow,
              edit: AccountEdit,
            },
            {
              name: 'reports',
              options: { label: 'Reports' },
              icon: <BiLineChart size={20} />,
              list: ComingSoon,
            },
            {
              name: 'extras',
              options: { label: 'Extras' },
              icon: <BiBadge size={20} />,
              list: ComingSoon,
            },
            {
              name: 'settings',
              options: { label: 'Settings' },
              icon: <BiCog size={20} />,
              list: ComingSoon,
            },
          ]}
          routerProvider={{
            ...routerProvider,
            routes: [
              {
                element: <SignupPage />,
                path: '/signup',
              },
            ] as typeof routerProvider.routes,
          }}
        />
      </ModalsProvider>
    </ThemeProvider>
  );
};

export default App;
