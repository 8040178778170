import { Skeleton } from '@mantine/core';
import { useOne } from '@pankod/refine-core';

export const usePopulateColumn = (resource: string, cell: any, callback: (data: any) => any) => {
  const { data, isLoading } = useOne<any>({
    resource: resource,
    id: cell.value,
  });

  if (isLoading) return <Skeleton height={22} />;

  return callback(data);
};
