import { Skeleton as MantineSkeleton, Stack } from '@mantine/core';

export const Skeleton: React.FC<any> = () => {
  return (
    <Stack>
      <Stack spacing="xs">
        <MantineSkeleton height={20} radius="sm" width={160} />
        <MantineSkeleton height={36} radius="sm" />
      </Stack>
      <Stack spacing="xs">
        <MantineSkeleton height={20} radius="sm" width={240} />
        <MantineSkeleton height={36} radius="sm" />
      </Stack>
      <Stack spacing="xs">
        <MantineSkeleton height={20} radius="sm" width={200} />
        <MantineSkeleton height={36} radius="sm" />
      </Stack>
    </Stack>
  );
};
