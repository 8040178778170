import { Drawer } from '@mantine/core';
import { useShowDrawer } from 'components/ShowDrawer';

export const ShowDrawer: React.FC<{ title?: string }> = ({ children, ...props }) => {
  const { opened, setOpened } = useShowDrawer();

  return (
    <Drawer
      opened={opened}
      padding="xl"
      position="right"
      size="xl"
      styles={{
        title: {
          fontSize: '22px',
          fontWeight: 700,
        },
      }}
      onClose={() => setOpened(false)}
      {...props}
    >
      {children}
    </Drawer>
  );
};
