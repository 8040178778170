import { Avatar, Badge, Box, Button, Group, Stack, Text } from '@mantine/core';
import { useNavigation } from '@pankod/refine-core';
import { ICustomer, IProduct, ISale } from 'interfaces';

type DetailProps = {
  record?: ISale;
};

export const ProductDetail: React.FC<{ record?: IProduct }> = ({ record }) => {
  const { show } = useNavigation();

  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Product</Text>
        <Group>
          <Avatar radius="sm" size="lg" src={record?.image} />
          <Box>
            {record?.brand} {record?.model} {record?.model_year}
          </Box>
        </Group>
      </Group>
      <Box>
        {record && (
          <Button
            size="xs"
            sx={{ marginLeft: '144px' }}
            onClick={() => show('products', record?.id)}
          >
            Show details
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export const CustomerDetail: React.FC<{ record?: ICustomer }> = ({ record }) => {
  const { show } = useNavigation();

  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Customer</Text>
        <Group>
          <Avatar radius="sm" size="lg" src={record?.image} />
          <Box>
            {record?.title} {record?.first_name} {record?.last_name}
          </Box>
        </Group>
      </Group>
      <Box>
        {record && (
          <Button
            size="xs"
            sx={{ marginLeft: '144px' }}
            onClick={() => show('customers', record?.id)}
          >
            Show details
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export const PaymentTypeDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Payment type</Text>
        <Badge color="blue">{record?.payment_type}</Badge>
      </Group>
    </Stack>
  );
};

export const PaymentStatusDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Payment status</Text>
        <Badge color="blue">{record?.payment_status}</Badge>
      </Group>
    </Stack>
  );
};
