import { Button, Container, Image, Paper, SimpleGrid, Stack, Title } from '@mantine/core';
import { useNavigation } from '@pankod/refine-core';
import { SITE_LOGO } from 'constants/env';
import { ISignup } from 'interfaces';
import { PasswordConfirmationField, PasswordField, UsernameField } from 'pages/accounts';
import { EmailField, FirstNameField, LastNameField } from 'pages/users';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { supabaseClient } from 'utils/supabase';

export const Signup: React.FC<any> = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleSubmit, ...formProps } = useForm<ISignup>();
  const { push } = useNavigation();

  const onSubmit = async (fieldValues: ISignup) => {
    setSubmitting(true);

    const { first_name, last_name, username, email, password } = fieldValues;
    const { error } = await supabaseClient.auth.signUp(
      {
        email,
        password,
      },
      {
        data: {
          first_name,
          last_name,
          username,
        },
      }
    );

    setSubmitting(false);

    if (error) {
      alert(error.message);
    } else {
      push('/');
    }
  };

  return (
    <Container my={40} size={420}>
      <Stack align="center">
        <Image height={96} src={SITE_LOGO} />
        <Title align="center" order={1}>
          Create account
        </Title>
      </Stack>
      <Paper mt={28} p={28} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid spacing="md">
            <FirstNameField formProps={formProps} placeholder="Richard" />
            <LastNameField formProps={formProps} placeholder="Hendricks" />
            <UsernameField formProps={formProps} placeholder="richard.hendricks" />
            <EmailField formProps={formProps} placeholder="richard.hendricks@piedpiper.com" />
            <PasswordField formProps={formProps} placeholder="Your password" />
            <PasswordConfirmationField formProps={formProps} placeholder="Your password again" />
            <Button fullWidth loading={isSubmitting} type="submit">
              Sign up
            </Button>
          </SimpleGrid>
        </form>
      </Paper>
    </Container>
  );
};
