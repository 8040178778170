import { Avatar, Badge, Box } from '@mantine/core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';
import { PageLayout } from 'components/PageLayout';
import { ShowDrawer, ShowDrawerProvider } from 'components/ShowDrawer';
import { Table, TableActions, TablePagination } from 'components/Table';
import { IProduct } from 'interfaces';
import { useMemo, useState } from 'react';
import { BiCar } from 'react-icons/bi';
import { ShowDetails } from './ShowDetails';

export const List: React.FC = () => {
  const [id, setId] = useState(0);

  const columns: Array<Column> = useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'image',
        Header: 'Image',
        accessor: 'image',
        Cell: ({ value }) => (
          <Avatar radius="sm" size="lg" src={value}>
            <BiCar size={28} />
          </Avatar>
        ),
      },
      {
        id: 'brand',
        Header: 'Brand',
        accessor: 'brand',
      },
      {
        id: 'model',
        Header: 'Model',
        accessor: 'model',
      },
      {
        id: 'model_year',
        Header: 'Model year',
        accessor: 'model_year',
      },
      // {
      //   id: 'stock_location_id',
      //   Header: 'Stock location',
      //   accessor: 'stock_location_id',
      // },
      {
        id: 'quantity',
        Header: 'Quantity',
        accessor: 'quantity',
        Cell: ({ value }) => <Badge color="blue">{value}</Badge>,
      },
      // {
      //   id: 'chassis_number',
      //   Header: 'Chassis number',
      //   accessor: 'chassis_number',
      // },
      // {
      //   id: 'color',
      //   Header: 'Color',
      //   accessor: 'color',
      // },
      // {
      //   id: 'power',
      //   Header: 'Power',
      //   accessor: 'power',
      // },
      // {
      //   id: 'weight_amount',
      //   Header: 'Weight',
      //   accessor: 'weight_amount',
      // },
      {
        id: 'price',
        Header: 'Price',
        accessor: 'price',
        Cell: ({ row }) => {
          const product: any = row.original;
          const price = new Intl.NumberFormat('nl-NL', {
            minimumFractionDigits: 2,
          }).format(product?.price);
          return `${product?.currency} ${price}`;
        },
      },
      // {
      //   id: 'created_at',
      //   Header: 'Created at',
      //   accessor: 'created_at',
      // },
      // {
      //   id: 'updated_at',
      //   Header: 'Updated at',
      //   accessor: 'updated_at',
      // },
      {
        id: 'action',
        accessor: 'id',
        Cell: ({ value, row }) => {
          const product: any = row.original;
          return (
            <TableActions
              withDeleteButton
              withEditButton
              withShowButton
              withShowDrawerButton
              id={value}
              label={`${product?.brand} ${product?.model}`}
              onShowDrawer={() => setId(value)}
            />
          );
        },
      },
    ],
    []
  );

  const tableProps = useTable<IProduct>({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ShowDrawerProvider>
      <PageLayout withCreateButton>
        <Box sx={{ position: 'relative' }}>
          <Table {...tableProps} />
        </Box>
        <TablePagination {...tableProps} />
      </PageLayout>
      <ShowDrawer title="Product details">
        <Box pt="md">
          <ShowDetails id={id} />
        </Box>
      </ShowDrawer>
    </ShowDrawerProvider>
  );
};
