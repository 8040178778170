import {
  Avatar,
  Box,
  Group,
  LoadingOverlay,
  Menu,
  Portal,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useGetIdentity, useLogout } from '@pankod/refine-core';
import { BiChevronRight, BiLogOut } from 'react-icons/bi';

export const User: React.FC<any> = () => {
  const { mutate: logout, isLoading } = useLogout();

  return (
    <>
      <Box sx={(theme) => ({ paddingTop: theme.spacing.sm })}>
        <Menu
          control={
            <UnstyledButton
              sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                '&:hover': {
                  backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                },
              })}
            >
              <UserProfile />
            </UnstyledButton>
          }
          placement="end"
          sx={{ width: '100%' }}
        >
          <Menu.Item icon={<BiLogOut size={14} />} onClick={() => logout()}>
            Log out
          </Menu.Item>
        </Menu>
      </Box>
      {isLoading && (
        <Portal zIndex={200}>
          <LoadingOverlay sx={{ position: 'fixed' }} visible={true} />
        </Portal>
      )}
    </>
  );
};

const UserProfile: React.FC<any> = () => {
  const theme = useMantineTheme();
  const { data } = useGetIdentity();

  if (!data) return <Box sx={{ height: '40px' }} />;

  return (
    <Group>
      <Avatar color={theme.primaryColor} radius="xl">
        {data?.user_metadata?.first_name[0].toUpperCase()}
      </Avatar>
      <Box sx={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {data?.user_metadata?.first_name} {data?.user_metadata?.last_name}
        </Text>
        <Text color="dimmed" size="xs">
          {data?.email}
        </Text>
      </Box>
      <BiChevronRight size={18} />
    </Group>
  );
};
