import { Text } from '@mantine/core';
import { PageLayout } from 'components/PageLayout';

export const ComingSoon = () => {
  return (
    <PageLayout>
      <Text>Coming Soon</Text>
    </PageLayout>
  );
};
