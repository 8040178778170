import { ActionIcon, Button, Container, Group, Title } from '@mantine/core';
import { useNavigation, useResource } from '@pankod/refine-core';
import { BiArrowBack, BiPlus } from 'react-icons/bi';

type PageLayoutProps = {
  children?: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  withBackButton?: boolean;
  withCreateButton?: boolean;
};

export const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  title,
  header,
  withBackButton,
  withCreateButton,
}) => {
  const { resource, resourceName } = useResource();
  const { create, goBack } = useNavigation();

  return (
    <Container fluid>
      <Group mt="-md" position="apart" sx={{ height: 96 }}>
        <Group>
          {withBackButton && (
            <ActionIcon variant="default" onClick={goBack}>
              <BiArrowBack size={16} />
            </ActionIcon>
          )}
          <Title order={2}>{title ? title : resource.label}</Title>
        </Group>
        {withCreateButton && (
          <Button leftIcon={<BiPlus />} onClick={() => create(resourceName)}>
            Create
          </Button>
        )}
        {header && header}
      </Group>
      {children}
    </Container>
  );
};
