import { Avatar, Badge, Box, Group, Stack } from '@mantine/core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';
import { PageLayout } from 'components/PageLayout';
import { ShowDrawer, ShowDrawerProvider } from 'components/ShowDrawer';
import { Table, TableActions, TablePagination, usePopulateColumn } from 'components/Table';
import { IAccount } from 'interfaces';
import { useMemo, useState } from 'react';
import { ShowDetails } from './ShowDetails';

export const List: React.FC = () => {
  const [id, setId] = useState(0);

  const columns: Array<Column> = useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'account',
        Header: 'Account',
        accessor: 'user_id',
        Cell: ({ cell }) =>
          usePopulateColumn('users', cell, (data) => (
            <Group>
              <Avatar radius="sm" size="lg" src={data?.data.image} />
              <Box>
                {data?.data.title} {data?.data.first_name} {data?.data.last_name}
              </Box>
            </Group>
          )),
      },
      {
        id: 'username',
        Header: 'Username',
        accessor: 'username',
      },
      {
        id: 'user.email',
        Header: 'Email',
        accessor: 'user_id',
        Cell: ({ cell }) => usePopulateColumn('users', cell, (data) => data?.data.email),
      },
      {
        id: 'user.phone',
        Header: 'Phone',
        accessor: 'user_id',
        Cell: ({ cell }) => usePopulateColumn('users', cell, (data) => data?.data.phone_number),
      },
      {
        id: 'user.gender',
        Header: 'Gender',
        accessor: 'user_id',
        Cell: ({ cell }) =>
          usePopulateColumn('users', cell, (data) => <Badge>{data?.data.gender}</Badge>),
      },
      // {
      //   id: 'created_at',
      //   Header: 'Created at',
      //   accessor: 'created_at',
      // },
      // {
      //   id: 'updated_at',
      //   Header: 'Updated at',
      //   accessor: 'updated_at',
      // },
      {
        id: 'action',
        accessor: 'user_id',
        Cell: ({ cell, row }) =>
          usePopulateColumn('users', cell, (data) => {
            const account: any = row.original;
            return (
              <TableActions
                withDeleteButton
                withEditButton
                withShowButton
                withShowDrawerButton
                id={account?.id}
                label={`${data?.data.first_name} ${data?.data.last_name}`}
                onShowDrawer={() => setId(account?.id)}
              />
            );
          }),
      },
    ],
    []
  );

  const tableProps = useTable<IAccount>({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ShowDrawerProvider>
      <PageLayout withCreateButton>
        <Stack spacing="lg">
          <Table {...tableProps} />
          <TablePagination {...tableProps} />
        </Stack>
      </PageLayout>
      <ShowDrawer title="Account details">
        <Box pt="md">
          <ShowDetails id={id} />
        </Box>
      </ShowDrawer>
    </ShowDrawerProvider>
  );
};
