import { AppShell as MantineAppShell } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { NavBarProvider } from './context';
import { Header } from './Header';
import { NavBar } from './NavBar';

export const AppShell: React.FC = ({ children }) => {
  const matches = useMediaQuery('(max-width: 768px)');

  return (
    <NavBarProvider>
      <MantineAppShell
        fixed
        header={matches ? <Header /> : undefined}
        navbar={<NavBar />}
        navbarOffsetBreakpoint="sm"
        padding="md"
      >
        {children}
      </MantineAppShell>
    </NavBarProvider>
  );
};
