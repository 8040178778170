import { Button, SimpleGrid } from '@mantine/core';
import { useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';
import {
  BrandField,
  ChassisNumberField,
  ColorField,
  ModelField,
  ModelYearField,
  PowerField,
  PriceField,
  QuantityField,
  StockLocationField,
  WeightField,
} from './Fields';

export const CreateForm: React.FC = () => {
  const {
    handleSubmit,
    refineCore: { formLoading, onFinish },
    ...formProps
  } = useForm({
    refineCoreProps: {
      redirect: 'list',
      resource: 'products',
    },
  });

  const { options } = useSelect({
    resource: 'stock_locations',
    optionLabel: 'name',
    optionValue: 'id',
  });

  return (
    <form onSubmit={handleSubmit(onFinish)}>
      <SimpleGrid spacing="md" sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        <StockLocationField formProps={formProps} options={options} />
        <BrandField formProps={formProps} />
        <ModelField formProps={formProps} />
        <ModelYearField formProps={formProps} />
        <QuantityField formProps={formProps} />
        <ChassisNumberField formProps={formProps} />
        <ColorField formProps={formProps} />
        <PowerField formProps={formProps} />
        <WeightField formProps={formProps} />
        <PriceField formProps={formProps} />
        <Button loading={formLoading} mt="xs" type="submit">
          Create
        </Button>
      </SimpleGrid>
    </form>
  );
};
