import { ActionIcon, Group, Stack, Text, Title } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useDelete, useNavigation, useResource } from '@pankod/refine-core';
import { useShowDrawer } from 'components/ShowDrawer';
import { singular } from 'pluralize';
import { BiDockRight, BiPencil, BiShow, BiTrash } from 'react-icons/bi';

type TableActionsProps = {
  id: number;
  label: string;
  withEditButton?: boolean;
  withDeleteButton?: boolean;
  withShowButton?: boolean;
  withShowDrawerButton?: boolean;
  onShowDrawer?: Function;
};

type ButtonProps = {
  id: number;
  label?: string;
  onClick?: Function;
};

export const TableActions: React.FC<TableActionsProps> = ({
  children,
  id,
  label,
  withDeleteButton,
  withEditButton,
  withShowButton,
  withShowDrawerButton,
  onShowDrawer,
}) => {
  return (
    <Group noWrap spacing="xs">
      {withShowButton && <ShowButton id={id} />}
      {withShowDrawerButton && <ShowDrawerButton id={id} onClick={onShowDrawer} />}
      {withEditButton && <EditButton id={id} />}
      {withDeleteButton && <DeleteButton id={id} label={label} />}
      {children}
    </Group>
  );
};

const ShowButton: React.FC<ButtonProps> = ({ id }) => {
  const { show } = useNavigation();
  const { resourceName } = useResource();

  return (
    <ActionIcon
      variant="hover"
      onClick={() => {
        show(resourceName, id);
      }}
    >
      <BiShow />
    </ActionIcon>
  );
};

const ShowDrawerButton: React.FC<ButtonProps> = ({ onClick }) => {
  const { setOpened } = useShowDrawer();

  return (
    <ActionIcon
      variant="hover"
      onClick={() => {
        onClick && onClick();
        setOpened(true);
      }}
    >
      <BiDockRight />
    </ActionIcon>
  );
};

const EditButton: React.FC<ButtonProps> = ({ id }) => {
  const { edit } = useNavigation();
  const { resourceName } = useResource();

  return (
    <ActionIcon
      variant="hover"
      onClick={() => {
        edit(resourceName, id);
      }}
    >
      <BiPencil />
    </ActionIcon>
  );
};

const DeleteButton: React.FC<ButtonProps> = ({ id, label }) => {
  const modals = useModals();
  const { mutate, isLoading } = useDelete();
  const { resourceName, resource } = useResource();

  const openConfirmModal = () =>
    modals.openConfirmModal({
      centered: true,
      title: `Delete ${singular(resource.label?.toLowerCase() ?? '')}`,
      children: (
        <Stack>
          <Title order={5}>{label}</Title>
          <Text size="sm">This action cannot be undone.</Text>
        </Stack>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      onConfirm: () => {
        mutate({ id: id, resource: resourceName });
      },
    });

  return (
    <ActionIcon loading={isLoading} variant="hover" onClick={openConfirmModal}>
      <BiTrash />
    </ActionIcon>
  );
};
