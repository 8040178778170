import { PasswordInput, TextInput } from '@mantine/core';
import { FieldInputProps } from 'interfaces';

export const UsernameField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.username && errors.username.message}
      label="Username"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('username', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const PasswordField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <PasswordInput
      error={errors.password && errors.password.message}
      label="Password"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('password', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const PasswordConfirmationField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <PasswordInput
      error={errors.password_confirmation && errors.password_confirmation.message}
      label="Confirm password"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('password_confirmation', { required: 'This field is required' })}
      {...props}
    />
  );
};
