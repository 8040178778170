import { Badge, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useNavigation, useResource } from '@pankod/refine-core';

type NavLinkProps = {
  active?: boolean;
  icon: React.ReactNode;
  color?: string;
  label?: string;
  badge?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const NavLink: React.FC<NavLinkProps> = ({ active, icon, label, badge, onClick }) => {
  const { primaryColor } = useMantineTheme();

  return (
    <UnstyledButton
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        fontWeight: active ? 500 : 400,
        color: active ? theme.colors[primaryColor][5] : 'inherit',
        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
      })}
      onClick={onClick}
    >
      <Group>
        {icon}
        <Text size="sm">{label}</Text>
        {badge && (
          <Badge color="red" sx={{ marginLeft: 'auto' }} variant="light">
            {badge}
          </Badge>
        )}
      </Group>
    </UnstyledButton>
  );
};

export const NavLinks: React.FC = () => {
  const { resources, resourceName } = useResource();
  const { list } = useNavigation();

  return (
    <>
      {resources.map(({ name, icon, label, options }) => (
        <NavLink
          key={name}
          active={name === resourceName}
          badge={options?.badge}
          icon={icon}
          label={label}
          onClick={() => list(name)}
        />
      ))}
    </>
  );
};
