import { Box } from '@mantine/core';
import { useShow } from '@pankod/refine-core';
import { FormSkeleton } from 'components/Form';
import { PageLayout } from 'components/PageLayout';
import { ICreditor } from 'interfaces';
import { EditForm } from './EditForm';

export const Edit: React.FC = () => {
  const { queryResult } = useShow<ICreditor>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <PageLayout withBackButton title="Edit creditor">
      <Box sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        {isLoading ? <FormSkeleton /> : <EditForm record={record} />}
      </Box>
    </PageLayout>
  );
};
