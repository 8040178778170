import { Navbar, ScrollArea } from '@mantine/core';
import { Brand } from './Brand';
import { useNavBar } from './hooks';
import { NavLinks } from './NavLinks';
import { User } from './User';

export const NavBar: React.FC = () => {
  const { opened } = useNavBar();

  return (
    <Navbar
      hidden={!opened}
      hiddenBreakpoint="sm"
      pb="xs"
      px="xs"
      sx={{ borderRight: 0 }}
      width={{ sm: 250, lg: 300 }}
    >
      <Navbar.Section>
        <Brand />
      </Navbar.Section>
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        <NavLinks />
      </Navbar.Section>
      <Navbar.Section>
        <User />
      </Navbar.Section>
    </Navbar>
  );
};
