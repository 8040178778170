import { useResource } from '@pankod/refine-core';
import { PageLayout } from 'components/PageLayout';
import { ShowDetails } from './ShowDetails';

export const Show: React.FC = () => {
  const { id } = useResource();

  return (
    <PageLayout withBackButton title="Account details">
      <ShowDetails id={id} />
    </PageLayout>
  );
};
