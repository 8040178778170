import { Radio, RadioGroup, TextInput } from '@mantine/core';
import { Controller } from '@pankod/refine-react-hook-form';
import { FieldInputProps, FieldProps } from 'interfaces';

export const TitleField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="title"
      render={({ field }) => (
        <RadioGroup
          error={errors.title?.message}
          label="Title"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        >
          <Radio label="Mr." value="mr" />
          <Radio label="Ms." value="ms" />
          <Radio label="Mrs." value="mrs" />
        </RadioGroup>
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export const FirstNameField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.first_name?.message}
      label="First name"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('first_name', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const LastNameField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.last_name?.message}
      label="Last name"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('last_name', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const ImageUrlField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.image?.message}
      label="Image URL"
      variant="default"
      {...register('image')}
      {...props}
    />
  );
};

export const EmailField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.email?.message}
      label="Email"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('email', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const PhoneField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.phone_number?.message}
      label="Phone"
      variant="default"
      {...register('phone_number')}
      {...props}
    />
  );
};

export const GenderField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="gender"
      render={({ field }) => (
        <RadioGroup
          error={errors.gender?.message}
          label="Gender"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        >
          <Radio label="Male" value="male" />
          <Radio label="Female" value="female" />
          <Radio label="Unspecified" value="unspecified" />
        </RadioGroup>
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};
