import { Avatar, Badge, Box, Group, Stack, Text } from '@mantine/core';
import { IUser } from 'interfaces';

type DetailProps = {
  record?: IUser;
};

export const ImageDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Avatar size="xl" src={record?.image} />
    </Stack>
  );
};

export const TitleDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Title</Text>
        <Text>{record?.title}</Text>
      </Group>
    </Stack>
  );
};

export const FirstNameDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>First name</Text>
        <Text>{record?.first_name}</Text>
      </Group>
    </Stack>
  );
};

export const LastNameDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Last name</Text>
        <Text>{record?.last_name}</Text>
      </Group>
    </Stack>
  );
};

export const EmailDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Email</Text>
        <Text>{record?.email}</Text>
      </Group>
    </Stack>
  );
};

export const PhoneDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Phone</Text>
        <Text>{record?.phone_number}</Text>
      </Group>
    </Stack>
  );
};

export const GenderDetail: React.FC<DetailProps> = ({ record }) => {
  return (
    <Stack>
      <Group>
        <Text sx={{ width: '128px' }}>Gender</Text>
        <Box>
          <Badge>{record?.gender}</Badge>
        </Box>
      </Group>
    </Stack>
  );
};
