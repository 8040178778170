import { Stack } from '@mantine/core';
import { useOne, useShow } from '@pankod/refine-core';
import { FormSkeleton } from 'components/Form';
import { IAccount, IUser } from 'interfaces';
import {
  EmailDetail,
  FirstNameDetail,
  GenderDetail,
  ImageDetail,
  LastNameDetail,
  PhoneDetail,
  TitleDetail,
} from 'pages/users';
import { UsernameDetail } from './Details';

export const ShowDetails: React.FC<{ id?: number | string }> = ({ id }) => {
  const { queryResult } = useShow<IAccount>({ id });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: userData } = useOne<IUser>({
    resource: 'users',
    id: record?.user_id || '',
    queryOptions: {
      enabled: !!record,
    },
  });
  const user = userData?.data;

  if (isLoading) {
    return (
      <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        <FormSkeleton />
      </Stack>
    );
  }

  return (
    <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
      <ImageDetail record={user} />
      <TitleDetail record={user} />
      <FirstNameDetail record={user} />
      <LastNameDetail record={user} />
      <UsernameDetail record={record} />
      <EmailDetail record={user} />
      <PhoneDetail record={user} />
      <GenderDetail record={user} />
    </Stack>
  );
};
