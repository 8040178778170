import { NumberInput, Select, TextInput } from '@mantine/core';
import { Controller } from '@pankod/refine-react-hook-form';
import { FieldInputProps, FieldProps } from 'interfaces';

export const StockLocationField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  options,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="stock_location_id"
      render={({ field }) => (
        <Select
          data={options}
          error={errors.stock_location_id?.message}
          label="Stock location"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        />
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export const BrandField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.brand?.message}
      label="Brand"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('brand', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const ModelField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.model?.message}
      label="Model"
      variant="default"
      wrapperProps={{ required: true }}
      {...register('model', { required: 'This field is required' })}
      {...props}
    />
  );
};

export const ModelYearField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="model_year"
      render={({ field }) => (
        <NumberInput
          hideControls
          error={errors.model_year?.message}
          label="Model year"
          variant="default"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        />
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export const QuantityField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="quantity"
      render={({ field }) => (
        <NumberInput
          error={errors.quantity?.message}
          label="Quantity"
          variant="default"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        />
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export const ChassisNumberField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="chassis_number"
      render={({ field }) => (
        <NumberInput
          hideControls
          error={errors.chassis_number?.message}
          label="Chassis number"
          variant="default"
          {...field}
          {...props}
        />
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};

export const ColorField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.color?.message}
      label="Color"
      variant="default"
      {...register('color')}
      {...props}
    />
  );
};

export const PowerField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="power"
      render={({ field }) => (
        <NumberInput
          hideControls
          error={errors.power?.message}
          label="Power"
          variant="default"
          {...field}
          {...props}
        />
      )}
    />
  );
};

export const WeightField: React.FC<FieldInputProps> = ({
  formProps: {
    formState: { errors },
    register,
  },
  ...props
}) => {
  return (
    <TextInput
      error={errors.weight_amount?.message}
      label="Weight"
      variant="default"
      {...register('weight_amount')}
      {...props}
    />
  );
};

export const PriceField: React.FC<FieldProps> = ({
  formProps: {
    control,
    formState: { errors },
  },
  ...props
}) => {
  return (
    <Controller
      control={control}
      name="price"
      render={({ field }) => (
        <NumberInput
          hideControls
          error={errors.price?.message}
          label="Price"
          variant="default"
          wrapperProps={{ required: true }}
          {...field}
          {...props}
        />
      )}
      rules={{ required: 'This field is required' }}
    />
  );
};
