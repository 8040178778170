import { Stack } from '@mantine/core';
import { useOne, useShow } from '@pankod/refine-core';
import { FormSkeleton } from 'components/Form';
import { IProduct, ISale } from 'interfaces';
import { CustomerDetail, PaymentStatusDetail, PaymentTypeDetail, ProductDetail } from './Details';

export const ShowDetails: React.FC<{ id?: number | string }> = ({ id }) => {
  const { queryResult } = useShow<ISale>({ id });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: userData } = useOne<ISale>({
    resource: 'users',
    id: record?.user_id || '',
    queryOptions: {
      enabled: !!record,
    },
  });
  const user = userData?.data;

  const { data: productData } = useOne<IProduct>({
    resource: 'products',
    id: record?.product_id || '',
    queryOptions: {
      enabled: !!record,
    },
  });
  const product = productData?.data;

  if (isLoading) {
    return (
      <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        <FormSkeleton />
      </Stack>
    );
  }

  return (
    <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
      <ProductDetail record={product} />
      <CustomerDetail record={user} />
      <PaymentTypeDetail record={record} />
      <PaymentStatusDetail record={record} />
    </Stack>
  );
};
