import { Button, SimpleGrid } from '@mantine/core';
import { useNavigation } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';
import { FormSkeleton } from 'components/Form';
import { ICustomer } from 'interfaces';
import {
  EmailField,
  FirstNameField,
  GenderField,
  ImageUrlField,
  LastNameField,
  PhoneField,
  TitleField,
} from 'pages/users';

export const EditForm: React.FC<{ record?: ICustomer }> = ({ record }) => {
  const { list } = useNavigation();

  const {
    handleSubmit,
    refineCore: {
      formLoading,
      mutationResult: { isLoading },
      onFinish,
    },
    ...formProps
  } = useForm({
    refineCoreProps: {
      id: record?.user_id,
      resource: 'users',
      redirect: false,
      onMutationSuccess() {
        list('customers');
      },
    },
  });

  return (
    <>
      <form hidden={formLoading ? true : false} onSubmit={handleSubmit(onFinish)}>
        <SimpleGrid spacing="md">
          <TitleField formProps={formProps} />
          <FirstNameField formProps={formProps} />
          <LastNameField formProps={formProps} />
          <ImageUrlField formProps={formProps} />
          <EmailField formProps={formProps} />
          <PhoneField formProps={formProps} />
          <GenderField formProps={formProps} />
          <Button loading={isLoading} mt="xs" type="submit">
            Update
          </Button>
        </SimpleGrid>
      </form>
      {formLoading && <FormSkeleton />}
    </>
  );
};
