import { Stack } from '@mantine/core';
import { useOne, useShow } from '@pankod/refine-core';
import { FormSkeleton } from 'components/Form';
import { IProduct } from 'interfaces';
import {
  BrandDetail,
  ChassisNumberDetail,
  ColorDetail,
  ImageDetail,
  ModelDetail,
  ModelYearDetail,
  PowerDetail,
  PriceDetail,
  QuantityDetail,
  WeightDetail,
} from './Details';

export const ShowDetails: React.FC<{ id?: number | string }> = ({ id }) => {
  const { queryResult } = useShow<IProduct>({ id });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: productData } = useOne<IProduct>({
    resource: 'products',
    id: record?.id || '',
    queryOptions: {
      enabled: !!record,
    },
  });
  const product = productData?.data;

  if (isLoading) {
    return (
      <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        <FormSkeleton />
      </Stack>
    );
  }

  return (
    <Stack sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
      <ImageDetail record={product} />
      <BrandDetail record={product} />
      <ModelDetail record={product} />
      <ModelYearDetail record={product} />
      <QuantityDetail record={product} />
      <ChassisNumberDetail record={product} />
      <ColorDetail record={product} />
      <PowerDetail record={product} />
      <WeightDetail record={product} />
      <PriceDetail record={product} />
    </Stack>
  );
};
