import { createContext, useState } from 'react';

type NavBarContextValue = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NavBarContext = createContext<NavBarContextValue>({
  opened: false,
  setOpened: () => {},
});

export const NavBarProvider: React.FC = ({ children }) => {
  const [opened, setOpened] = useState(false);

  return (
    <NavBarContext.Provider value={{ opened, setOpened }}>
      {children}
    </NavBarContext.Provider>
  );
};
