import { Button, SimpleGrid } from '@mantine/core';
import { useCreate } from '@pankod/refine-core';
import { FieldValues, useForm } from '@pankod/refine-react-hook-form';
import {
  EmailField,
  FirstNameField,
  GenderField,
  ImageUrlField,
  LastNameField,
  PhoneField,
  TitleField,
} from 'pages/users';

export const CreateForm: React.FC = () => {
  const {
    handleSubmit,
    refineCore: { formLoading, onFinish },
    ...formProps
  } = useForm({
    refineCoreProps: {
      action: 'create',
      redirect: 'list',
    },
  });

  const userMutation = useCreate();

  const onSubmit = (values: FieldValues) => {
    // Create the user
    userMutation.mutate(
      {
        resource: 'users',
        values: values,
      },
      {
        onSuccess(data) {
          // Create the customer and populate the user ID
          onFinish({
            user_id: data?.data.id,
          });
        },
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid spacing="md" sx={(theme) => ({ maxWidth: theme.breakpoints.xs })}>
        <TitleField formProps={formProps} />
        <FirstNameField formProps={formProps} />
        <LastNameField formProps={formProps} />
        <ImageUrlField formProps={formProps} />
        <EmailField formProps={formProps} />
        <PhoneField formProps={formProps} />
        <GenderField formProps={formProps} />
        <Button loading={userMutation.isLoading || formLoading} mt="xs" type="submit">
          Create
        </Button>
      </SimpleGrid>
    </form>
  );
};
