import { PageLayout } from 'components/PageLayout';
import { CreateForm } from './CreateForm';

export const Create: React.FC = () => {
  return (
    <PageLayout withBackButton title="New customer">
      <CreateForm />
    </PageLayout>
  );
};
