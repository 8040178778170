import { Pagination } from '@mantine/core';
import { UseTableProps } from '@pankod/refine-react-table';

export const TablePagination: React.FC<UseTableProps> = (props) => {
  const {
    pageOptions,
    gotoPage,
    state: { pageIndex },
  } = props;

  return <Pagination page={pageIndex + 1} total={pageOptions.length} onChange={gotoPage} />;
};
